<template>
  <!-- 新闻页 -->
  <div class="news">
    <div class="banner_box">
      <img src="../../../assets/newsImg/newsbanner.jpg" alt="" />
    </div>
    <div class="container">
      <div class="titile">
        <h2>最新资讯</h2>
        <p>THE LATEST INFORMATION</p>
      </div>
      <el-row :gutter="40" v-loading='loading'>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          v-for="(item, index) in newsData"
          :key="index"
          class="case_box"
          @click="goNewsDetail(item.id)"
        >
          <div class="img_box">
            <img
              :src="`/api/profile/product/${item.route}`"
              alt=""
            />
          </div>
          <div class="category">{{ item.category }}</div>
          <h3 class="case_title">{{ item.name }}</h3>
          <p class="descri">{{ item.author }}</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getNewsList } from "../../../api/index";

export default {
  name: "newsLayOut",
  components: {},
  data() {
    return {
      newsData: null,
      loading:true,//loading状态

    };
  },
  created() {
    getNewsList().then((res) => {
      console.log(res)
      this.loading=false
      this.newsData = res.rows;
    });
  },
  methods:{
    goNewsDetail(data){
      this.$router.push({path:'/newsDetail',query:{id:data}})
    }
  }
};
</script>

<style scoped lang="less">
.banner_box {
  img {
    width: 100%;
  }
}
.titile {
  text-align: center;
  padding: 65px 0;
  h2 {
    font-size: 30px;
    font-weight: lighter;
    color: #333;
    font-family: BaseTitle-webfont;
  }
  p {
    color: rgb(125, 125, 125);
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 100;
    font-family: 方正兰亭超细黑;
  }
}
.news {
  .case_box {
    min-height: 610px !important;
    margin: 30px 0;
  }
  .category {
    margin: 10px 0;
    color: #666;
    font-size: 14px;
  }
}
.descri {
  color: #666;
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 16px;
  text-align: left;
  display: -webkit-box;
  overflow: hidden;
  margin-top: 20px;
}
.img_box {
  overflow: hidden;
  img {
    transition: 0.5s;
    width: 100%;
    height:380px;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.case_box {
  cursor: pointer;
}
.case_title {
  &:hover {
    text-decoration: underline;
  }
}
</style>
